<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link>
                    <template v-if="organization">
                    &gt; <router-link :to="{ name: 'organization-dashboard', params: { realm: this.$route.params.organizationId } }">{{ organization.name }}</router-link>
                    </template>
                </p>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS records</h1>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card class="pa-0" v-if="clientTokenList">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Client tokens ({{ clientTokenList.length }})</v-app-bar-title>
                        </v-app-bar>

                        <v-card-text v-if="clientTokenList.length === 0">
                            <p>A client token grants permissions to your application to manage organizations, users, orders, and more via the Unicorn Springs API.</p>
                            <!-- <v-btn style="background-color: green darken-2; color: #ffffff;" elevation="2" @click="createNewItemDialog = true">
                                Add
                            </v-btn> -->
                        </v-card-text>

                        <!-- TODO: for each item, add @click with navigation link to edit client token page with that token id -->
                        <v-list dense v-if="clientTokenList.length > 0">
                            <v-list-item v-for="(item, idx) in clientTokenList" :key="idx">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.label }} <!-- .{{ item.domain }} -->
                                        <v-chip v-if="item.value === null" label small class="px-1 ml-2 green white--text">New</v-chip>
                                        <v-chip v-if="Array.isArray(item.value) && item.value.length === 1" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        <template v-if="Array.isArray(item.value) && item.value.length > 1">
                                            <!-- TODO: do we need to limit how many are on the line, and offer a popup or something to see the full list of ipaddr? -->
                                            <v-chip v-for="(ipaddr, idx) in item.value" :key="idx" label small class="px-1 ml-2 grey white--text">{{ ipaddr }}</v-chip>
                                        </template>
                                        <!-- <v-chip v-if="item.status === 'new'" label small class="px-1 ml-2 yellow">{{ item.status }}</v-chip>
                                        <v-chip v-if="item.status === 'pending'" label small class="px-1 ml-2 green lighten-4">{{ item.status }}</v-chip>
                                        <v-btn icon small color="green darken-2" @click="openEditItemDialog(item)">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width></font-awesome-icon>
                                        </v-btn> -->
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style>
/* vuetify places the icon too low from the top of the textarea */
.v-input .v-input__append-outer {
    margin-top: 4px !important;
}
</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        organization: null,
        clientTokenList: null,

        displayToken: null,

        submitFormTimestamp: null,

        createNewItemDialog: false,
        newItemDomain: null,
        newItemLabel: null,

        editItemDialog: false,
        editItemId: null,
        editItemDomain: null,
        editItemLabel: null,
        editItemValue: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isNewItemFormComplete() {
            return /* typeof this.newItemDomain === 'string' && this.newItemDomain.length > 0 && */ typeof this.newItemLabel === 'string' && this.newItemLabel.length > 0;
        },
        isEditItemFormComplete() {
            return typeof this.editItemDomain === 'string' && this.editItemDomain.length > 0 && typeof this.editItemLabel === 'string' && this.editItemLabel.length > 0;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newItemLabel = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.$activateInput('newItemLabelInput'); }, 1);
                });
            }
        },
    },
    methods: {
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadClientTokenList() {
            try {
                this.$store.commit('loading', { loadClientTokenList: true });
                const response = await this.$client.organization(this.$route.params.organizationId).clientToken.search();
                if (response?.list) {
                    this.clientTokenList = response.list;
                }
            } catch (err) {
                console.error('loadClientTokenList failed', err);
            } finally {
                this.$store.commit('loading', { loadClientTokenList: false });
            }
        },
        /*
        async editItem() {
            try {
                this.$store.commit('loading', { editItem: true });
                const response = await this.$client.main().dynamicSharedDomain.edit({ name: this.editItemLabel }, { status: this.editItemStatus });
                if (response?.isEdited) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    const idx = this.clientTokenList?.findIndex((item) => item.name === this.editItemLabel);
                    if (Number.isInteger(idx) && idx > -1) {
                        this.clientTokenList.splice(idx, 1, { name: this.editItemLabel, status: this.editItemStatus });
                    } else {
                        this.loadClientTokenList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('editItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { editItem: false });
            }
        },
        */
    },
    mounted() {
        this.loadOrganization();
        this.loadClientTokenList();
    },
};
</script>
